import "./../base-site.js"

$(document).ready(function() {
    let contact = new Contact();
    contact.init();
});

class Contact {
 	init() {
	 	this.applyBindings();
 	}
 
 	applyBindings() {
 		let self = this;
		$(document).on('click', '#submit-contact', function(e) {
			e.preventDefault();
			$('#current_password,#password,#password_confirmation').removeClass('uk-form-danger');
			$('.form-warning').hide();
			let id = $(this).closest('form').attr('id');
            self.send_contact(id); 
            return false;
        });
 	}

 	send_contact(id) {
		let url = window.feelbettr.baseUrl + '/site_submission';
		let form = $('form').serializeArray();
		$('#loader').show();
		$('.widget-item form').hide();

		$.ajaxSetup({
		    headers: {
		        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		    }
		});

		// Reset form
		$.ajax({
		  	url: url,
		  	context: document.body,
		  	type: "POST",
		  	data: form,
		    success: function(result) {
		    	if(result == 1) {
		    		$('form#' + id).hide();
		    		$('#success').fadeIn();
		    	} else {
		    		$.each(result, function(i,val) {
		    			$('#' + i + '-message').text(val).fadeIn();
			        });
			        $('.widget-item form').show();
		    	}

		    	$('#loader').hide();
		    	$('#form-loader').hide();
		    }
		});
	}
}